import { createRouter, createWebHistory } from 'vue-router';
import { ROUTES } from '@/enums/RoutesEnum';
import type { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('@/layout/FullPageLayout.vue'),
        redirect: {
            name: ROUTES.WIZARD,
        },
        children: [
            {
                name: ROUTES.WIZARD,
                path: 'wizard',
                component: () => import('@/layout/WizardLayout.vue'),
                redirect: {
                    name: ROUTES.CLINIC_SELECTION,
                },
                children: [
                    {
                        component: () => import('@/pages/ClinicSelection.vue'),
                        name: ROUTES.CLINIC_SELECTION,
                        path: 'clinic-selection',
                    },
                    {
                        component: () => import('@/pages/ServiceSelection.vue'),
                        name: ROUTES.SERVICE_SELECTION,
                        path: 'service-selection',
                    },
                    {
                        component: () => import('@/pages/Staff/StaffSelection.vue'),
                        name: ROUTES.STAFF_SELECTION,
                        path: 'staff-selection',
                    },
                    {
                        component: () => import('@/pages/ClientForm.vue'),
                        name: ROUTES.CLIENT_FORM,
                        path: 'client-form',
                    },
                ],
            },
            {
                component: () => import('@/pages/Summary.vue'),
                name: ROUTES.SUMMARY,
                path: 'summary',
                beforeEnter: (to, from, next) => {
                    if (from.name === ROUTES.CLIENT_FORM) {
                        next();
                    } else {
                        next({
                            name: ROUTES.CLINIC_SELECTION,
                        });
                    }
                },
                props: (route) => ({
                    orderErrorCode: route.query.orderErrorCode,
                    appointmentId: route.query.appointmentId,
                }),
            },
            {
                component: () => import('@/pages/PaymentCallback.vue'),
                name: ROUTES.PAYMENT_SUMMARY,
                path: 'payment/:appointmentId',
                props: (route) => ({
                    resultCode: route.query.resultCode, // CSOB result code
                    paymentStatus: route.query.paymentStatus, // CSOB payment status
                }),
            },
            {
                component: () => import('@/pages/CancelOrder.vue'),
                name: ROUTES.CANCEL_ORDER,
                path: 'Booking/Cancel',
                props: (route) => ({
                    appointmentId: route.query.aud,
                    companyId: route.query.cud,
                }),
            },
            {
                component: () => import('@/pages/NotFound.vue'),
                name: ROUTES.NOT_FOUND,
                path: '404',
            },
        ],
    },
    {
        path: '/clinicSelection',
        redirect: {
            name: ROUTES.CLINIC_SELECTION,
        },
    },
    {
        path: '/service-selection',
        redirect: {
            name: ROUTES.SERVICE_SELECTION,
        },
    },

    {
        path: '/:pathMatch(.*)*',
        redirect: {
            name: ROUTES.CLINIC_SELECTION,
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    // Keep the params when we route to the same path;
    const isClinicSelection = [from.name, to.name].every((substring) => ROUTES.CLINIC_SELECTION.includes(substring as string));
    next(isClinicSelection ? false : undefined);
});

export default router;
