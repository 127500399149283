import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faXTwitter, faInstagram, faApple, faGoogle, faSquareFacebook, faSquareInstagram, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faMemoCircleCheck as falMemoCircleCheck } from '@fortawesome/sharp-light-svg-icons';
import { faCalendarPlus as fasrCalendarPlus, faUser as fasrUser, faExclamationCircle as fasrExclamationCircle, faCircle as fasrCircle, faClock as fasrClock } from '@fortawesome/sharp-regular-svg-icons';
import {
    faCheck as fassCheck,
    faHouse as fassHouse,
    faLocationDot as fassLocationDot,
    faScrewdriverWrench as fassScrewdriverWrench,
    faClock as fassClock,
    faPen as fassPen,
    faCalendarDays as fassCalendarDays,
    faMagnifyingGlass as fassMagnifyingGlass,
    faQuestion as fassQuestion,
    faAmbulance as fassAmbulance,
    faX as fassX,
    faThumbsUp as fassThumbsUp,
    faCheck as fasCheck,
    faXmark as fasXMark,
    faLoader as fasLoader,
    faChevronRight as fasChevronRight,
    faChevronDown as fasChevronDown,
    faTooth as fasTooth,
    faExclamationCircle as fasExclamationCircle,
    faEnvelope as fasEnvelope,
    faPhone as fasPhone,
    faGlobe as fasGlobe,
    faArrowRightFromBracket as fasArrowRightFromBracket,
    faBracketRound as fasBracketRound,
    faExclamation as fasExclamation,
    faLightEmergencyOn as fasLightEmergencyOn,
} from '@fortawesome/sharp-solid-svg-icons';
import {
    faExclamationCircle as fastExclamationCircle,
    faUser as fastUser,
    faTooth as fastTooth,
    faCircle as fastCircle,
    faLocationDot as fastLocationDot,
    faGlobe as fastGlobe,
    faPhone as fastPhone,
    faEnvelope as fastEnvelope,
} from '@fortawesome/sharp-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
    fasExclamation,
    fasChevronRight,
    fasChevronDown,
    fasLoader,
    fasXMark,
    fasCheck,
    fassCalendarDays,
    fasrCalendarPlus,
    faApple,
    faGoogle,
    fassX,
    fassThumbsUp,
    fastUser,
    fassAmbulance,
    fastTooth,
    fassMagnifyingGlass,
    fastLocationDot,
    fassQuestion,
    fassHouse,
    faFacebookF,
    faXTwitter,
    faInstagram,
    fastGlobe,
    fasrUser,
    fassLocationDot,
    fassScrewdriverWrench,
    fassClock,
    fassPen,
    fastCircle,
    fastPhone,
    fastEnvelope,
    fassCheck,
    fastExclamationCircle,
    fasTooth,
    fasEnvelope,
    fasPhone,
    fasGlobe,
    faSquareFacebook,
    faSquareInstagram,
    faSquareXTwitter,
    fasExclamationCircle,
    fasrExclamationCircle,
    fasArrowRightFromBracket,
    fasBracketRound,
    fasrCircle,
    fasrClock,
    falMemoCircleCheck,
    fasLightEmergencyOn
);

export default FontAwesomeIcon;
