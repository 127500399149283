<template>
    <RouterView v-slot="{ Component }">
        <template v-if="Component">
            <Transition>
                <KeepAlive>
                    <Suspense>
                        <div class="h-full w-full">
                            <Toast />
                            <LoadingOverlay v-if="isLoading" />
                            <component v-show="!isLoading" :is="Component"></component>
                        </div>
                    </Suspense>
                </KeepAlive>
            </Transition>
        </template>
    </RouterView>
</template>

<script setup lang="ts">
import Toast from 'primevue/toast';
import { onMounted, ref, watch } from 'vue';
import LoadingOverlay from '@/pages/Overlays/LoadingOverlay.vue';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore();
const loadingStore = useLoadingStore();

const isLoading = ref(loadingStore.isLoading);

onMounted(async () => {
    // Set language
    if (userStore.getLanguageCode) {
        await userStore.setLanguage(userStore.getLanguageCode);
    }
});

watch(
    () => loadingStore.isLoading,
    (newValue) => {
        isLoading.value = newValue;
    }
);
</script>
