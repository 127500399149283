import PrimeVue, { PrimeVueChangeTheme, PrimeVueConfiguration, defaultOptions } from 'primevue/config';
import { usePassThrough } from 'primevue/passthrough';
import Tailwind from 'primevue/passthrough/tailwind';
import { toRaw } from 'vue';
import i18n from '@/plugins/i18n';
import button from '@/plugins/primeVue/pt/button';
import calendar from '@/plugins/primeVue/pt/calendar';
import checkbox from '@/plugins/primeVue/pt/checkbox';
import dropdown from '@/plugins/primeVue/pt/dropdown';
import inputmask from '@/plugins/primeVue/pt/inputmask';
import inputtext from '@/plugins/primeVue/pt/inputtext';
import menu from '@/plugins/primeVue/pt/menu';
import textarea from '@/plugins/primeVue/pt/textarea';
import tooltip from '@/plugins/primeVue/pt/tooltip';

const CustomTailwind = usePassThrough(Tailwind, {
    directives: {
        tooltip,
    },
    checkbox,
    inputtext,
    dropdown,
    menu,
    button,
    calendar,
    textarea,
    inputmask,
});
const primeVuePlugin = {
    install: (app) => {
        app.use(PrimeVue, {
            pt: CustomTailwind,
            ripple: false,
            unstyled: true,
            locale: {
                ...defaultOptions.locale,
                firstDayOfWeek: 1,
            },
        });
    },
};

export function setPrimeVueLanguage(primeVueInstance: { config: PrimeVueConfiguration; changeTheme: PrimeVueChangeTheme }, languageCode: string) {
    const objectKey = 'primeVue';
    type TranslationFunction = (key: string) => string;

    const primeVueMessages = toRaw(i18n.global.getLocaleMessage(languageCode)[objectKey]);

    if (!primeVueMessages || !primeVueInstance) {
        return;
    }

    Object.keys(primeVueMessages).forEach((key) => {
        if (Array.isArray(primeVueMessages[key])) {
            const arrayOfMessages = [];

            for (let i = 0; i < primeVueMessages[key].length; i++) {
                arrayOfMessages.push((i18n.global.t as TranslationFunction)(`${objectKey}.${key}.${i}`));
            }
            primeVueInstance.config.locale[key] = arrayOfMessages;
        } else {
            primeVueInstance.config.locale[key] = (i18n.global.t as TranslationFunction)(`${objectKey}.${key}`);
        }
    });
}

export default primeVuePlugin;
