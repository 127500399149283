<template>
    <div class="absolute flex h-full w-full flex-col items-center justify-center">
        <LottieAnimation class="h-[90vh]" ref="anim" :animation-data="XDENTLogo" :loop="true" :speed="1"> </LottieAnimation>
    </div>
</template>

<script setup lang="ts">
import { LottieAnimation } from 'lottie-web-vue';
import { onMounted, ref } from 'vue';
import XDENTLogo from '@/assets/xdentLogo.json';

const anim = ref();

onMounted(async () => {
    anim.value.play();
});
</script>
