import Color from 'color';
import { Settings } from 'luxon';
import { defineStore } from 'pinia';
import { PrimeVueChangeTheme, PrimeVueConfiguration } from 'primevue/config';
import bgThemes from '@/codelists/BgThemes';
import colorThemes from '@/codelists/ColorThemes';
import languages from '@/codelists/Languages';
import { IExtentedClinic } from '@/interfaces/IExtendedClinic';
import i18n, { loadLocaleMessages, setI18nLanguage } from '@/plugins/i18n';
import { setPrimeVueLanguage } from '@/plugins/primeVue';
import { useWizardStore } from '@/stores/wizard';
import { APISharedCodelistDto, APISharedModelsOnlineBookingOnlineBookingDtoOtherInfoItem, APISharedModelsOnlineBookingOnlineBookingDtoBookingDetailSetting } from '../../generated';

interface IState {
    languageCode: string;
    currencyCode: string;
    phonePrefix: string;
    clinics: IExtentedClinic[];
    codelists: {
        [key: string]: Array<APISharedCodelistDto>;
    };
    isThemeFromUrl: boolean;
    isColorFromUrl: boolean;
    openStaffId: string;
    otherInputFields: Array<APISharedModelsOnlineBookingOnlineBookingDtoOtherInfoItem>;
    defaultFormValues: {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
    };
    websiteDataDetail: Array<APISharedModelsOnlineBookingOnlineBookingDtoBookingDetailSetting>;
    phoneIsRequired: boolean;
    emailIsRequired: boolean;
    htmlMsgBeforeBook: string;
    htmlScriptExecuted: boolean;
}

export const useUserStore = defineStore('user', {
    state: (): IState => ({
        languageCode: '',
        currencyCode: '',
        phonePrefix: '',
        clinics: [],
        codelists: {},
        isThemeFromUrl: false,
        isColorFromUrl: false,
        openStaffId: '',
        otherInputFields: [],
        defaultFormValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
        },
        websiteDataDetail: [],
        phoneIsRequired: true,
        emailIsRequired: true,
        htmlMsgBeforeBook: '',
        htmlScriptExecuted: false,
    }),
    getters: {
        getLanguageCode: (state: IState) => state.languageCode,
        getCurrencyCode: (state: IState) => state.currencyCode,
        getPhonePrefix: (state: IState) => state.phonePrefix,
        getClinics: (state: IState) => state.clinics,
        getOtherInputFields: (state: IState) => state.otherInputFields,
        getDefaultFormValues: (state: IState) => state.defaultFormValues,
        getPhonePrefixes: (state: IState) => {
            return state.codelists?.PHONE_PREFIX?.map((prefixItem) => ({
                code: prefixItem.code,
            }));
        },
        getSelectedClinicDetail: (state: IState) => {
            return state.websiteDataDetail.find((detailItem) => detailItem.idLocation === useWizardStore().getSelectedClinic?.idLocation);
        },
        getPhoneIsRequired: (state: IState) => {
            return state.phoneIsRequired;
        },
        getEmailIsRequired: (state: IState) => {
            return state.emailIsRequired;
        },
        getHtmlMsgBeforeBook: (state: IState) => {
            return state.htmlMsgBeforeBook;
        },
        getHtmlScriptExecuted: (state: IState) => {
            return state.htmlScriptExecuted;
        }
    },
    actions: {
        async setLanguage(languageCode: string, primeVueInstance?: { config: PrimeVueConfiguration; changeTheme: PrimeVueChangeTheme }) {
            // Check if language is supported inside code list
            if (!languages.find((language) => language.code === languageCode)) {
                return;
            }

            this.languageCode = languageCode;

            // Load locale messages
            if (!i18n.global.availableLocales.includes(languageCode)) {
                await loadLocaleMessages(i18n, languageCode);
            }

            // Set i18n language
            setI18nLanguage(i18n, languageCode);

            // Set luxon locale
            Settings.defaultLocale = languageCode;

            // Set primevue language
            setPrimeVueLanguage(primeVueInstance, languageCode);
        },
        setCurrency(currencyCode: string) {
            this.currencyCode = currencyCode;
        },
        setPhonePrefix(phonePrefix: string) {
            this.phonePrefix = phonePrefix;
        },
        setClinics(clinics: IExtentedClinic[]) {
            this.clinics = clinics;
        },
        setCodelists(codelists: { [key: string]: Array<APISharedCodelistDto> }) {
            this.codelists = codelists;
        },
        setColorFromUrl() {
            this.isColorFromUrl = true;
        },
        setThemeFromUrl() {
            this.isThemeFromUrl = true;
        },
        setOpenStaffId(openStaffId) {
            this.openStaffId = openStaffId;
        },
        setCustomInputs(otherInputFields: Array<APISharedModelsOnlineBookingOnlineBookingDtoOtherInfoItem>) {
            this.otherInputFields = otherInputFields;
        },
        setDefaultFormValues(defaultFormValues: { firstName: string; lastName: string; email: string; phone: string }) {
            this.defaultFormValues = defaultFormValues;
        },
        setIsPhoneRequired(isRequired: boolean) {
            this.phoneIsRequired = isRequired;
        },
        setIsEmailRequired(isRequired: boolean) {
            this.emailIsRequired = isRequired;
        },
        setHtmlMsgBeforeBook(htmlMsgBeforeBook: string) {
            this.htmlMsgBeforeBook = htmlMsgBeforeBook;
        },
        setHtmlScriptExecuted(htmlScriptExecuted: boolean) {
            this.htmlScriptExecuted = htmlScriptExecuted;
        },
        setTheme(color?: string | null, idScheme?: string | null): void {
            if (!color || !idScheme) return;

            const root = document.querySelector(':root');

            // Find booking scheme code from codelist
            const bookingScheme = this.codelists['BOOKING_SCHEME']?.find((list) => list.idCodelist === idScheme);

            if (bookingScheme && bookingScheme.code) {
                // Remove all classes from root
                root.setAttribute('class', '');

                // Remove all styles from root
                root.removeAttribute('style');

                // Get bg theme
                let bgTheme = bgThemes.find((themeBg) => themeBg.idCodeList === bookingScheme.code);

                if (!bgTheme) {
                    bgTheme = bgThemes[0]; // Set default bg theme
                }

                // Get color theme
                const colorTheme = colorThemes.find((themeCol) => themeCol.hexColor === color);

                // Add bg theme and color theme to root
                if (colorTheme) {
                    root.classList.add(bgTheme.themeName, bgTheme.isSpecial ? colorTheme.specialThemeName : colorTheme.themeName);
                } else {
                    // If color is not in color themes, manipulate the default color theme
                    const LIGHTER_PERC = 0.1;
                    const DARKER_PERC = 0.1;

                    // eslint-disable-next-line new-cap
                    const clr = Color(color);

                    const primaryStr = clr.rgb().string();
                    const secondaryStr = clr.darken(DARKER_PERC).rgb().string();
                    const primaryLighterStr = clr.lighten(LIGHTER_PERC).rgb().string();

                    const doc = document.documentElement;

                    doc.style.setProperty('--primary', primaryStr);
                    doc.style.setProperty('--primary-lighter', primaryStr);
                    doc.style.setProperty('--secondary', primaryStr);

                    if (!bgTheme.isSpecial) {
                        doc.style.setProperty('--select-item-bg-color', clr.rgb().alpha(0.5).string());
                        doc.style.setProperty('--select-item-border-color', clr.rgb().alpha(0.6).string());
                    } else {
                        doc.style.setProperty('--select-item-bg-color', clr.rgb().alpha(0.5).string());
                        doc.style.setProperty('--select-item-border-color', clr.rgb().alpha(0.6).string());
                        doc.style.setProperty('--item-bg-color', secondaryStr);
                        doc.style.setProperty('--staff-detail-section-item-bg', secondaryStr);
                        doc.style.setProperty('--second-item-bg-color', secondaryStr);
                        doc.style.setProperty('--item-bg-hover-color', primaryLighterStr);
                        doc.style.setProperty('--content-background', primaryStr);
                        doc.style.setProperty('--button-panel-background', secondaryStr);
                        doc.style.setProperty('--button-panel-box-shadow-color', primaryLighterStr);
                    }
                    root.classList.add(bgTheme.themeName);
                }
            }
        },
        setWebsiteDataDetail(websiteDataDetail: Array<APISharedModelsOnlineBookingOnlineBookingDtoBookingDetailSetting>) {
            this.websiteDataDetail = websiteDataDetail;
        },
        reset() {
            this.$reset();
        },
    },
    persist: {
        paths: ['languageCode'],
    },
});
